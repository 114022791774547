import request from '@/utils/request'

/**
 * 内容分类管理
 * */

// 获取内容分类列表
export function getCategoryList(data) {
  return request({
    url: '/cms/admin/articleCategory/page',
    method: 'post',
    data,
  })
}

// 获取内容分类下拉列表
export function getCategorySelect(params) {
  return request({
    url: '/cms/admin/articleCategory/getActivityType',
    method: 'get',
    params,
  })
}
// 新增内容分类

export function addCategory(data) {
  return request({
    url: '/cms/admin/articleCategory/saveArticleCategory',
    method: 'post',
    data,
  })
}

// 编辑内容分类
export function editCategory(data) {
  return request({
    url: '/cms/admin/articleCategory/updateArticleCategory',
    method: 'post',
    data,
  })
}

// 删除内容分类
export function deleteCategory(params) {
  return request({
    url: '/cms/admin/articleCategory/deleteArticleCategory',
    method: 'delete',
    params,
  })
}

/**
 * 内容管理
 * */

// 查询文章列表
export function getArticleList(data) {
  return request({
    url: '/cms/admin/article/page',
    method: 'post',
    data,
  })
}

// 新增文章
export function saveArticle(data) {
  return request({
    url: '/cms/admin/article/saveArticle',
    method: 'post',
    data,
  })
}

// 编辑文章
export function editArticle(data) {
  return request({
    url: '/cms/admin/article/updateArticle',
    method: 'post',
    data,
  })
}

// 删除文章
export function deleteArticle(params) {
  return request({
    url: '/cms/admin/article/delete',
    method: 'delete',
    params,
  })
}

// 获取文章详情
export function getContentDetail(params) {
  return request({
    url: '/cms/admin/article/get',
    method: 'get',
    params,
  })
}

// 获取微信公众号推文
export function getWexinArticle(params) {
  return request({
    url: '/cms/wx/getWxArticleList',
    method: 'get',
    params,
  })
}

// 获取微信公众号推文详情
export function getWeixinArticleDetail(params) {
  return request({
    url: '/cms/wx/getWxArticleDetail',
    method: 'get',
    params,
  })
}

// 内容创建新标签

export function enterpriseQueryMaterialTag(params) {
  return request({
    url: '/user/admin/enterprise/queryMaterialTag',
    method: 'get',
    params,
  })
}
export function enterpriseAddMaterialTag(data) {
  return request({
    url: '/user/admin/enterprise/addMaterialTag',
    method: 'post',
    data,
  })
}
export function enterpriseEditMaterialTag(data) {
  return request({
    url: '/user/admin/enterprise/updateMaterialTag',
    method: 'post',
    data,
  })
}

export function enterpriseDeleteMaterialTag(tagId) {
  return request({
    url: `/user/admin/enterprise/delMaterialTag/${tagId}`,
    method: 'POST',
  })
}

// 查询内容素材库
export function enterprisequeryMaterial(params) {
  return request({
    url: '/user/admin/enterprise/queryMaterial',
    method: 'get',
    params,
  })
}

export function enterpriseAddMaterial(data) {
  return request({
    url: '/user/admin/enterprise/addMaterial',
    method: 'POST',
    data,
  })
}

export function enterpriseEditMaterial(data) {
  return request({
    url: '/user/admin/enterprise/updateMaterial',
    method: 'POST',
    data,
  })
}

export function enterpriseDeleteMaterial(id) {
  return request({
    url: `/user/admin/enterprise/delMaterial/${id}`,
    method: 'POST',
  })
}

export function newsSaveOrUpdate(data) {
  return request({
    url: `/user/admin/event/news/saveOrUpdate`,
    method: 'POST',
    data,
  })
}

export function getNewsList(data) {
  return request({
    url: `/user/admin/event/news/list`,
    method: 'POST',
    data,
  })
}

export function getNewsContentDetail(newsCode) {
  return request({
    url: `/user/admin/event/news/getContent/${newsCode}`,
    method: 'GET',
  })
}

export function newsContentSave(data) {
  return request({
    url: `/user/admin/event/news/content/saveOrUpdate`,
    method: 'POST',
    data,
  })
}

export function updateNewsSort(data) {
  return request({
    url: `/user/admin/event/news/updateNewsSort`,
    method: 'POST',
    data,
  })
}
